// @import '~animate.css/animate.css';
// @import "~vue2-dropzone/dist/vue2Dropzone.min.css";

@import '~@simonwep/pickr/dist/themes/nano.min.css';
@import '~vue-slider-component/theme/default.css';
@import '~vue-tour/dist/vue-tour.css';

@import '~vue-gallery/node_modules/blueimp-gallery/css/blueimp-gallery.css';


@import 'clientSpecific/settings';
@import 'utilities/media';
@import 'utilities/mixins';
@import 'utilities/reset';
@import 'utilities/animate.css';
@import 'utilities/transitions';



@import 'style';

@import 'components/pdf_preview';
@import 'components/accordion';
@import 'components/buttons';
@import 'components/card';
@import 'components/datepicker';
@import 'components/form';
@import 'components/menus';
@import 'components/statistics';
@import 'components/multiselect.css';
@import 'components/notification';
@import 'components/pagination';
@import "components/tabs";
@import "components/titles";
@import 'components/tooltip';
@import 'components/tags';
@import 'components/todo';
@import 'components/progress';
@import 'components/tiptap.scss';
@import 'components/prism.scss';

@import 'components/drawer';
@import 'vue-prism-editor/dist/prismeditor.min.css';

@import 'pages/index';
@import 'vue-advanced-cropper/dist/style.css';
